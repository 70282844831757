.delete-addr-title-bar .modal-title[data-v-fd4cbc3b] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.delete-addr-body-text[data-v-fd4cbc3b] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.delete-addr-title-bar span[data-v-fd4cbc3b] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.delete-addr-bottom-bar[data-v-fd4cbc3b] {
  border-top: 2px solid #dbdbdb !important;
  position: relative;
}
.yt-form-spacer[data-v-fd4cbc3b] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}
.pp-delete-addr-btn-modal[data-v-fd4cbc3b] {
  width: 48%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.pp-delete-addr-btn-modal[data-v-fd4cbc3b]:hover {
  border-color: #fff;
}
.pp-delete-addr-btn-light-grey[data-v-fd4cbc3b] {
  color: var(--color-dark-grey);
}
.pp-delete-addr-btn-dark-grey[data-v-fd4cbc3b] {
  color: var(--color-black);
}
.pp-add-edit-btn[data-v-fd4cbc3b] {
  box-shadow: none !important;
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.delete-addr-title-bar span[data-v-fd4cbc3b] {
    font-size: 18px;
    line-height: 1.11;
}
.delete-addr-body-text[data-v-fd4cbc3b] {
    font-size: 16px;
    line-height: 1.13;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.delete-addr-title-bar span[data-v-fd4cbc3b] {
    font-size: 18px;
    line-height: 1.11;
}
.delete-addr-body-text[data-v-fd4cbc3b] {
    font-size: 15px;
    line-height: 1.2;
    padding-top: 0 !important;
}
}
