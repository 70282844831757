.contentcontainer[data-v-9af10c0e] {
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.item[data-v-9af10c0e] {
  padding-bottom: 30px;
}
.item-details[data-v-9af10c0e] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}
.cardcontainer[data-v-9af10c0e] {
  margin-top: 60px;
}
.border-radius-10[data-v-9af10c0e] {
  border-radius: 10px;
}
.yt-main-wrapper[data-v-9af10c0e] {
  padding: 60px 40px 70px;
}
.yt-main-wrapper .item-title[data-v-9af10c0e] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
  margin-bottom: 20px;
}
.yt-inner-wrap .yt-items.item[data-v-9af10c0e] {
  padding-top: 80px;
  padding-bottom: 0;
}
.yt-inner-wrap .yt-items.item[data-v-9af10c0e]:first-child {
  padding-top: 0;
}
@media (min-width: 1900px) {
.yt-main-wrapper[data-v-9af10c0e] {
    padding: 80px 40px 70px;
}
}
@media (max-width: 1280px) {
.yt-main-wrapper[data-v-9af10c0e] {
    margin-left: 20px;
    margin-right: 20px;
}
}
@media (min-width: 768px) {
.yt-cm-mobile-bread[data-v-9af10c0e] {
    display: none;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-main-wrapper[data-v-9af10c0e] {
    margin: 20px 0;
    padding: 40px 20px;
}
.yt-main-wrapper .item-title[data-v-9af10c0e] {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.13;
    letter-spacing: normal;
    margin-bottom: 11px;
}
.item-details[data-v-9af10c0e] {
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.14px;
}
.yt-inner-wrap .yt-items.item[data-v-9af10c0e] {
    padding-top: 20px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.pageroute[data-v-9af10c0e] {
    color: gray;
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1.17;
}
.yt-profile-mb-ttl.profile-pg-title[data-v-9af10c0e] {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.11;
}
.yt-cm-mobile-bread[data-v-9af10c0e] {
    margin-top: 8px;
    margin-bottom: 10px;
}
.yt-main-wrapper[data-v-9af10c0e] {
    margin: 10px 0 20px;
    padding: 20px;
}
.yt-main-wrapper .item-title[data-v-9af10c0e] {
    font-size: 16px;
    line-height: 1.13;
}
.item-details[data-v-9af10c0e] {
    line-height: 1.92;
    letter-spacing: 0.12px;
    font-size: 12px;
}
.yt-inner-wrap .yt-items.item[data-v-9af10c0e] {
    padding-top: 20px;
}
}
