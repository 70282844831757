/*
* YT Product Slider CSS
*/
.yt-sgl-product-fav[data-v-e02ce42d] {
  width: 24px;
}
.yt-sgl-product-off[data-v-e02ce42d] {
  background-color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.yt-sgl-product-cpnt[data-v-e02ce42d] {
  border: 1px solid #cae2fe;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  position: relative;
  transition: all .55s ease;
}
.yt-sgl-product-cpnt[data-v-e02ce42d]:hover {
  box-shadow: 0 10px 50px -12px rgba(171,171,171,.9);
  transform: translateY(-10px);
  transition: all .55s ease;
}
.yt-sgl-component-wrapper[data-v-e02ce42d] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-e02ce42d] {
  outline: none !important;
  box-shadow: none;
}
.yt-sgl-product-add-btn.buttoncart[data-v-e02ce42d] {
  position: relative;
  bottom: -1px;
  width: 100%;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: var(--color-dark-black);
}
.price.yt-sgl-price-wrap[data-v-e02ce42d] {
  margin-bottom: 23px;
}
.yt-sgl-product-title[data-v-e02ce42d] {
  font-size: 20px;
  line-height: 0.95;
  color: var(--color-black);
  margin: 25px 0 14px;
}
.yt-product-bg-image[data-v-e02ce42d] {
  width: 100%;
  height: 184px;
  background-size: cover;
  background-position: center top;
  margin: 5px auto 0;
  cursor: pointer;
  max-width: 184px;
}
.cm-bdr-rad-prdt-btn[data-v-e02ce42d] {
  overflow: visible !important;
}
.yt-sgl-product-img[data-v-e02ce42d] {
  max-height: 150px;
}
.yt-sgl-component-more[data-v-e02ce42d] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.yt-sgl-comonent-top-title[data-v-e02ce42d] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.yt-sgl-product-off[data-v-e02ce42d] {
  background-color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.yt-sgl-product-sale-price[data-v-e02ce42d] {
  color: var(--color-blue);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-sgl-product-reg-price2[data-v-e02ce42d] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-sgl-product-add-btn[data-v-e02ce42d] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
}
.yt-cm-row-margin[data-v-e02ce42d] {
  margin: 0 -5px;
}
.cm-col-prdt-col[data-v-e02ce42d] {
  padding: 0 5px;
}
@media (min-width: 1400px) {
.yt-cm-row-margin[data-v-e02ce42d] {
    margin: 0 -10px;
}
.cm-col-prdt-col[data-v-e02ce42d] {
    padding: 0 10px;
}
.cm-xxl-3[data-v-e02ce42d] {
    flex: 0 0 25%;
    max-width: 25%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-cm-row-margin .cm-col-prdt-col[data-v-e02ce42d] {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 10px !important;
}
.cm-col-prdt-col[data-v-e02ce42d] {
    padding: 0 10px !important;
}
.yt-sgl-product-off[data-v-e02ce42d] {
    font-size: 12px;
    line-height: 1.17;
    min-width: 35px;
}
.yt-sgl-product-title[data-v-e02ce42d] {
    font-size: 14px;
    line-height: 0.71;
    margin-top: 15px;
    margin-bottom: 8px;
}
.price.yt-sgl-price-wrap .yt-sgl-product-sale-price[data-v-e02ce42d],
  .price.yt-sgl-price-wrap .yt-sgl-product-reg-price2[data-v-e02ce42d] {
    font-size: 12px;
    line-height: 1.17;
    font-weight: 500;
    margin-bottom: 16px;
}
.yt-sgl-product-add-btn.buttoncart[data-v-e02ce42d] {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.price.yt-sgl-price-wrap[data-v-e02ce42d] {
    margin-bottom: 16px;
}
.yt-allproduct-main-wrap[data-v-e02ce42d] {
    margin-top: 0px !important;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.yt-cm-row-margin .cm-col-prdt-col[data-v-e02ce42d] {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 6.5px 0 5px !important;
}
.yt-sgl-product-title[data-v-e02ce42d] {
    font-size: 16px;
    line-height: 1.19;
    margin-top: 20px;
    margin-bottom: 23px;
}
.price.yt-sgl-price-wrap .yt-sgl-product-sale-price[data-v-e02ce42d],
  .price.yt-sgl-price-wrap .yt-sgl-product-reg-price2[data-v-e02ce42d] {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 18px;
}
.yt-sgl-product-add-btn.buttoncart[data-v-e02ce42d] {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: normal;
}
.price.yt-sgl-price-wrap[data-v-e02ce42d] {
    margin-bottom: 18px;
}
.yt-allproduct-main-wrap[data-v-e02ce42d] {
    margin-top: 15px !important;
}
.yt-cm-row-margin[data-v-e02ce42d] {
    margin: 0 -5px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-sgl-product-title[data-v-e02ce42d] {
    font-size: 18px;
    margin-top: 27px;
    margin-bottom: 15px;
}
.yt-sgl-product-off[data-v-e02ce42d] {
    min-width: 50px;
    font-size: 16px;
    line-height: 1.13;
}
}
