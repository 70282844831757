/*Order Detail css*/
.hc-breadcrumbs[data-v-9239ae45] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.order-details-main-wrapper[data-v-9239ae45] {
  padding: 40px 40px 42px;
}
.hc-current[data-v-9239ae45] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.hc-mb-30[data-v-9239ae45] {
  margin-bottom: 30px;
}
.hc-mb-80[data-v-9239ae45] {
  margin-bottom: 80px;
}
.radius-10[data-v-9239ae45] {
  border-radius: 10px;
}
.hc-beckfrom-ct-page[data-v-9239ae45] {
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  cursor: pointer;
  display: inline-block;
}
.hcp-back-arrow[data-v-9239ae45] {
  cursor: pointer;
}
.order-tracing-details[data-v-9239ae45] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-blue);
  text-decoration: underline;
}
.order-details-status[data-v-9239ae45] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  position: absolute;
  bottom: 20px;
  text-align: right;
  right: 0;
}
.order-details-sub-title[data-v-9239ae45] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.order-details-status-bar[data-v-9239ae45] {
  border-top: 2px solid #dbdbdb;
  border-bottom: 2px solid #dbdbdb;
}
.order-step-1 .order-status-date[data-v-9239ae45] {
  color: var(--color-dark-grey);
  padding-left: 10px;
}
.order-step-1 h4[data-v-9239ae45],
.order-details-address-type[data-v-9239ae45] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: var(--color-black);
}
.order-details-address-details[data-v-9239ae45] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
}
.order-details-status-bar .order-details-status-wrap[data-v-9239ae45] {
  border-left: 2px solid #dbdbdb;
}
.order-details-status-bar .order-details-status-wrap li[data-v-9239ae45] {
  position: relative;
  padding-left: 10px;
}
.order-details-status-icn[data-v-9239ae45] {
  position: absolute;
  left: -17px;
  top: 0;
}
.order-details-contact[data-v-9239ae45] {
  font-size: 15px;
  line-height: 1.2;
  color: var(--color-blue);
}
.order-details-message[data-v-9239ae45] {
  font-size: 16px;
  line-height: 1.19;
  color: var(--color-dark-grey);
}
.order-details-status.on-the-way[data-v-9239ae45]::after {
  content: "";
  width: 7px;
  height: 7px;
  background: var(--color-green);
  border-radius: 100%;
  display: block;
  position: absolute;
  bottom: 3px;
  left: -15px;
}
.pp-order-product-ttl[data-v-9239ae45] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.67;
  color: var(--color-black);
}
.order-ul-list-none[data-v-9239ae45] {
  list-style: none;
}
.op-order-quantity[data-v-9239ae45] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.op-order-product-price[data-v-9239ae45] {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-green);
}
.order-prodict-type-container[data-v-9239ae45] {
  max-width: 59vw;
  overflow-x: auto;
}

/* width */
.order-prodict-type-container[data-v-9239ae45]::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.order-prodict-type-container[data-v-9239ae45]::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 
/* Handle */
.order-prodict-type-container[data-v-9239ae45]::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.order-prodict-type-container[data-v-9239ae45]::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.order-prodict-type th[data-v-9239ae45],
.order-prodict-type td[data-v-9239ae45] {
  padding: 3px;
}
.order-prodict-type th[data-v-9239ae45] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
  text-transform: uppercase;
}
.order-prodict-type td[data-v-9239ae45] {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-black);
}
.od-product-img[data-v-9239ae45] {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  max-width: 133px;
  width: 133px;
  height: 133px;
}
.od-product-img img[data-v-9239ae45] {
  width: 117px;
  height: 117px;
  object-fit: cover;
  object-position: top;
}
.order-number-wrap .order-tag[data-v-9239ae45],
.order-date-wrap .order-tag[data-v-9239ae45] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.order-number-wrap .order-tag-val[data-v-9239ae45],
.order-date-wrap .order-tag-val[data-v-9239ae45] {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: var(--color-green);
}
.order-bdr-between[data-v-9239ae45] {
  width: 2px;
  background-color: #f6f8fa;
  margin: 0 15px;
  height: 18px;
}
.order-write-review[data-v-9239ae45] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-blue);
  text-decoration: underline;
}
.order-cancel-btn[data-v-9239ae45] {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.69px;
  text-align: left;
  color: var(--color-orange);
}
.pp-od-no-ttl[data-v-9239ae45] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-od-no-text.mb-0[data-v-9239ae45] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-order-btn[data-v-9239ae45] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.order-product-info[data-v-9239ae45] {
  padding-left: 40px;
}
.od-single-prd-details-wrap[data-v-9239ae45] {
  position: relative;
}
.order-product-quanity[data-v-9239ae45] {
  position: absolute;
  right: 0;
  top: 30px;
}
.order-details-main-wrapper .sinlge-mo-dt-wrap[data-v-9239ae45] {
  padding-left: 173px;
}
@media (min-width: 1280px) {
.od-cm-col-pad[data-v-9239ae45] {
    padding: 0 40px;
}
}
@media (min-width: 1900px) {
.od-cm-col-pad[data-v-9239ae45] {
    padding: 0 20px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.order-details-main-wrapper[data-v-9239ae45] {
    padding: 35px 30px;
}
.hc-beckfrom-ct-page[data-v-9239ae45] {
    font-size: 26px;
}
.order-details-sub-title[data-v-9239ae45] {
    margin-bottom: 15px;
}
.order-details-status.on-the-way[data-v-9239ae45]::after {
    bottom: 6px;
}
.order-details-status-bar[data-v-9239ae45] {
    margin-top: 0 !important;
}
.order-prodict-type th[data-v-9239ae45]:first-child,
  .order-prodict-type td[data-v-9239ae45]:first-child {
    padding: 3px !important;
}
.order-prodict-type th[data-v-9239ae45],
  .order-prodict-type td[data-v-9239ae45] {
    padding-left: 70px !important;
}
.pp-order-product-ttl[data-v-9239ae45] {
    line-height: 1.25;
}
.od-single-prd-details-wrap .od-product-img.p-1[data-v-9239ae45] {
    align-self: flex-start;
}
.order-product-info[data-v-9239ae45] {
    padding-left: 20px;
}
.order-details-main-wrapper .sinlge-mo-dt-wrap[data-v-9239ae45] {
    padding-left: 115px;
}
.order-number-wrap .order-tag[data-v-9239ae45],
  .order-date-wrap .order-tag[data-v-9239ae45],
  .order-number-wrap .order-tag-val[data-v-9239ae45],
  .order-date-wrap .order-tag-val[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.36;
}
.order-write-review[data-v-9239ae45] {
    font-size: 16px;
    line-height: 1.19;
}
.order-prodict-type th[data-v-9239ae45] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.order-prodict-type td[data-v-9239ae45] {
    font-size: 16px;
    line-height: 1.31;
}
.od-product-img[data-v-9239ae45] {
    width: 95px;
    height: 95px;
}
.od-product-img img[data-v-9239ae45] {
    width: 72px;
    height: 72px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) and (min-width: 768px) {
.od-product-img[data-v-9239ae45] {
    width: 70px;
    height: 70px;
}
.od-product-img img[data-v-9239ae45] {
    width: 57px;
    height: 57px;
}
.od-single-prd-details-wrap[data-v-9239ae45] {
    margin-bottom: 0 !important;
    padding-bottom: 5px !important;
    padding-top: 28px !important;
}
}
@media (max-width: 1279.92px) {
.hc-beckfrom-ct-page[data-v-9239ae45] {
    margin-bottom: 10px;
}
.order-tracing-details[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.14;
}
.order-details-sub-title[data-v-9239ae45] {
    font-size: 18px;
    line-height: 1.11;
    margin: 5px 0 17px !important;
}
.order-details-address-details[data-v-9239ae45] {
    color: var(--color-dark-grey);
    font-size: 14px;
    line-height: 1.36;
}
.order-details-main-wrapper[data-v-9239ae45] {
    padding: 23px 20px 20px;
}
.order-details-main-wrapper .sinlge-mo-dt-wrap[data-v-9239ae45] {
    padding-left: 80px;
}
.od-product-img[data-v-9239ae45] {
    max-width: 70px;
    align-self: flex-start;
}
.order-product-info[data-v-9239ae45] {
    padding-left: 10px;
}
.order-number-wrap .order-tag[data-v-9239ae45],
  .order-date-wrap .order-tag[data-v-9239ae45],
  .order-number-wrap .order-tag-val[data-v-9239ae45],
  .order-date-wrap .order-tag-val[data-v-9239ae45] {
    font-size: 12px;
    line-height: 1.58;
}
.order-write-review[data-v-9239ae45] {
    padding: 0;
    font-size: 14px;
    line-height: 1.36;
}
.pp-order-product-ttl[data-v-9239ae45] {
    font-size: 16px;
    line-height: 1.13;
}
.order-prodict-type th[data-v-9239ae45] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.order-prodict-type td[data-v-9239ae45] {
    font-size: 16px;
    line-height: 1.31;
}
.order-product-price[data-v-9239ae45] {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
}
.order-details-contact[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.14;
}
.order-cancel-btn[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.order-details-main-wrapper[data-v-9239ae45] {
    padding: 15px;
    position: relative;
    margin: 67px 0 !important;
    padding-bottom: 55px;
}
.order-details-main-wrapper .sinlge-mo-dt-wrap[data-v-9239ae45] {
    padding: 0;
    max-width: 60%;
}
.order-bdr-between[data-v-9239ae45] {
    display: none;
}
.order-write-review[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.36;
    padding: 0;
    text-decoration: none;
}
.order-number-wrap .order-tag[data-v-9239ae45],
  .order-date-wrap .order-tag[data-v-9239ae45] {
    font-size: 12px;
    line-height: 1.58;
}
.order-number-wrap .order-tag-val[data-v-9239ae45],
  .order-date-wrap .order-tag-val[data-v-9239ae45] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-sp-my-order-tdet-wrap[data-v-9239ae45] {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 10px 0px;
}
  /* .od-product-img {
    max-width: 70px;
    -ms-flex-item-align: start;
    align-self: flex-start;
  } */
.od-product-img[data-v-9239ae45] {
    width: 70px;
    height: 70px;
}
.od-product-img img[data-v-9239ae45] {
    width: 57px;
    height: 57px;
}
.order-product-info[data-v-9239ae45] {
    padding-left: 14px;
}
.pp-order-product-ttl[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.36;
    max-width: 160px;
}
.order-product-quanity[data-v-9239ae45] {
    top: 15px;
}
.order-prodict-type th[data-v-9239ae45] {
    font-size: 10px;
    line-height: 1.1;
}
.order-prodict-type td[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.5;
}
.op-order-product-price[data-v-9239ae45] {
    width: 100%;
    padding-right: 0 !important;
    text-align: right;
}
.order-tracing-details[data-v-9239ae45] {
    font-size: 12px;
    line-height: 1.17;
}
.order-details-status[data-v-9239ae45] {
    left: 100px;
    right: auto;
    bottom: 40px;
}
.order-details-status-bar[data-v-9239ae45] {
    margin: 0 !important;
}
.order-details-sub-title[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.36;
    margin-bottom: 26px;
    margin-top: 0;
}
.order-details-shipping-barmy-3[data-v-9239ae45] {
    padding-top: 15px;
}
.order-details-shipping-barmy-3 .order-details-address-type[data-v-9239ae45],
  .order-details-shipping-barmy-3 .order-details-address-details[data-v-9239ae45] {
    font-size: 15px;
    line-height: 1.2;
}
.order-cancel-wrap[data-v-9239ae45] {
    width: 100%;
    position: absolute;
    left: 15px;
    right: 0;
    bottom: -40px;
    text-align: left !important;
    padding: 0 !important;
}
.order-cancel-wrap .order-cancel-btn[data-v-9239ae45] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
}
.op-order-product-price[data-v-9239ae45] {
    font-size: 15px;
    line-height: 1.2;
}
.op-order-product-price[data-v-9239ae45] {
    position: absolute;
    right: 0;
    bottom: 40px;
}
.order-product-info .order-ul-list-none[data-v-9239ae45] {
    padding-top: 25px !important;
}
.order-product-quanity .order-ul-list-none[data-v-9239ae45],
  .order-details-status-bar .order-ul-list-none[data-v-9239ae45] {
    padding-top: 0 !important;
}
}
